import React , {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import "./ProductCollection.css"
import { addScrollPosition, changeSiteNumber, addHomeProductsJson, changeShouldScroll } from "../../../Redux/actions"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { createImageUrl } from "../../helper/createImgUrl"
import Footer from "../../footer/Footer"
import Header from "../../header/Header"
import Filter from "../../products/collection/Filter"
import OptionsContainer from "./OptionsContainer"
import { fetchProducts, fetchSearchItems } from "../../helper/apiService"

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

function Products(props) {

	// 
	const [category, setCategory] = useState(props.match ? props.match.params.category : "")
	const [search, setSearch] = useState(props.match ? props.match.params.search : "")
	// const [subCategories, setSubCategories] = useState("")
	// const [gender, setGender] = useState("")
	let query = useQuery()
	const history = useHistory()

	// Json
	const [collection, setCollection] = useState([])
	const [isJsonLoading, setIsJsonLoading] = useState(false)
	const [isContinueJsonLoading, setIsContinueJsonLoading] = useState(false)
	const [isEndOfCollection, setIsEndOfCollection] = useState(false)
	const [isFetchErr, setIsFetchErr] = useState(false)

	// Redux
	const savedProductCollection = useSelector(state => state.homeProductsApi)
	const savedScrollPosition = useSelector(state => state.scrollPosition)
	const savedSiteNumber = useSelector(state => state.siteNumber)
	const savedShouldScroll = useSelector(state => state.shouldScroll)
	const dispatch = useDispatch()

	//
	const [siteNumber, setSiteNumber] = useState(savedSiteNumber)

	// Filter
	const [showFilter, setShowFilter] = useState(false)

	// Fix
	// const [fix, setFix] = useState(0)

	// Filter settings in parent
	// gender
	const [genderColl, setGenderColl] = useState([
		{name: "Damen", idName: "women", isSelected: query.get("gender") === "women"},
		{name: "Herren", idName: "men", isSelected: query.get("gender") === "men"}
	])

	// fashion
	const [fashionCategoriesColl, setFashionCategoriesColl] = useState([
		{name: "Kleidung", idName: "clothing", isSelected: query.get("category") ? query.get("category").includes("clothing") : ""},
		{name: "Sneaker", idName: "sneaker", isSelected: query.get("category") ? query.get("category").includes("sneaker") : ""},
		{name: "Accessories", idName: "accesoires", isSelected: query.get("category") ? query.get("category").includes("accesoires") : ""}
	])
	// electronic
	const [electronicCategoriesColl, setElectronicCategoriesColl] = useState([
		{name: "Smartphone", idName: "spmartphone", isSelected: query.get("category") ? query.get("category").includes("spmartphone") : ""},
		{name: "TV", idName: "tv", isSelected: query.get("category") ? query.get("category").includes("tv") : ""},
		{name: "Audio", idName: "audio", isSelected: query.get("category") ? query.get("category").includes("audio") : ""},
		{name: "Kopfhörer", idName: "headphones", isSelected: query.get("category") ? query.get("category").includes("headphones") : ""},
		{name: "Computer", idName: "computer", isSelected: query.get("category") ? query.get("category").includes("computer") : ""},
		{name: "Gaming", idName: "gaming", isSelected: query.get("category") ? query.get("category").includes("gaming") : ""},
		{name: "Monitor", idName: "monitor", isSelected: query.get("category") ? query.get("category").includes("monitor") : ""},
		{name: "Tablet", idName: "tablet", isSelected: query.get("category") ? query.get("category").includes("tablet") : ""},
		{name: "Smartwatch", idName: "smartwatch", isSelected: query.get("category") ? query.get("category").includes("smartwatch") : ""},
		{name: "Kamara", idName: "camara", isSelected: query.get("category") ? query.get("category").includes("camara") : ""},
	])

	// Scroll
	const executeScroll = position => {window.scrollTo(0, position)}

	useEffect(() => {
		//if (savedSiteNumber !== 1) {dispatch(changeSiteNumber(1))}
		// eslint-disable-next-line

		executeScroll(0)

		if (category) document.title = `${category.toUpperCase()} Deals`
		else if (search) document.title = `${search.toUpperCase()} Deals`

		// dealWithQueryParams("sortBy", query.get("sortBy"))
		// dealWithQueryParams("gender", query.get("gender"))
	}, [])



	// Params
	useEffect(() => {
		setIsJsonLoading(true)

		if (search !== undefined && search !== null) {
			// Search
			manageSearchJSON(search, props.location.state ? props.location.state.searchCollection : undefined)
		} else {
			// Category
			setIsJsonLoading(true)
			fetchProducts(category, query.get("category"), collection, query.get("sortBy"), query.get("gender"), true, fetchCallback, siteNumber)
		}
	}, [history])


	// Manage Seatch Collection
	const manageSearchJSON = (searchValue, collection) => {
		if (collection && collection.length > 0) {
			setCollection(collection)
			setIsJsonLoading(false)
		} else {
			setCollection([])
			fetchSearchItems(searchValue, searchFetchCallback, 24, undefined, true)
			setIsJsonLoading(true)
		}
	}

	// Deal with Params
	// const dealWithQueryParams = (key, value) => {
	// 	// console.log("lllllllll", query)
	// 	// const params = new URLSearchParams()
	// 	const params = query
	// 	if (value) {
	// 	  params.append(key, value)
	// 	} else {
	// 	  params.delete(key)
	// 	}
	// 	history.push({search: params.toString()})
	// }

	// Fetch Callback
	const fetchCallback = (finishWithError, res, reset) => {
		if (!finishWithError) {
			setJsonCollection(res, reset)
		}
	}
	// Search
	const searchFetchCallback = (searchValue, res, override) => {
		setJsonCollection(res, override)
	}

	// Set Collecton
	const setJsonCollection = (responseJson, reset) => {
		let newCollection = collection

		if (!responseJson) {
			setIsJsonLoading(false)
			setIsContinueJsonLoading(false)
			return
		}

		if (reset) newCollection = responseJson
		else newCollection.push(...responseJson)

		if (responseJson.length === 0) setIsEndOfCollection(true)
		
		setCollection(newCollection)
		setIsJsonLoading(false)
		setIsContinueJsonLoading(false)
		dispatch(addHomeProductsJson(newCollection))
	}


	// Scroll
	useEffect(() => {
		if (collection.length > 0 && savedShouldScroll && savedProductCollection !== 0) {
			console.log("Scroll")
			scrollToItem()
		}

		// eslint-disable-next-line 
	}, [collection])

	// SCROLL TO ITEM
	const scrollToItem = () => {
		console.log("------- SCROLL POSITION:", savedScrollPosition, savedSiteNumber)
		if (savedScrollPosition !== 0) {
			executeScroll(savedScrollPosition)
		}

		dispatch(changeShouldScroll(false))
	}

	// Save Scroll Position
	const saveScrollPosition = () => {
		console.log("------- SAFE SCrol position", window.scrollY, savedSiteNumber)
		dispatch(changeShouldScroll(true))
		dispatch(addScrollPosition(window.scrollY))
	}



	// Callbacks
	const showFilterCallback = (show) => {
		setShowFilter(show)
	}

	const setFilterCallback = (genderCollection, fashionCategoriesCollection, electronicCategoriesCollection) => {
		console.log("SET FILTER CALLBACK", genderCollection, fashionCategoriesCollection, electronicCategoriesCollection)
		setGenderColl(genderCollection)
		setFashionCategoriesColl(fashionCategoriesCollection)
		setElectronicCategoriesColl(electronicCategoriesCollection)
	}

	const reloadCollectionCallback = (searchText, collection) => {
		resetScrollData()
		setSiteNumber(1)
		executeScroll(0)
		setCategory("")
		setSearch(searchText)
		manageSearchJSON(searchText, collection)
	}

	// reset redux scroll infos
	const resetScrollData = () => {
		dispatch(changeShouldScroll(false))
		dispatch(addScrollPosition(0))
		dispatch(changeSiteNumber(1))
	}



	const continueAction = () => {
		setIsContinueJsonLoading(true)

		if (search !== undefined && search !== null) {
			// Search
			fetchSearchItems(search, searchFetchCallback, 24, collection, false)
		} else {
			// Category
			// console.log("continues", query, query.get("category"), "/ cat:", category, query.get("gender"))
			// console.log("test 1", new URLSearchParams(history.location.search))
			fetchProducts(category, query.get("category"), collection, query.get("sortBy"), query.get("gender"), false, fetchCallback)
		}

		// Site number
		// console.log("---> New Site Number", siteNumber + 1)
		dispatch(changeSiteNumber(siteNumber + 1))
		setSiteNumber(siteNumber + 1)
	}


	const getPathTitle = (title) => {
		let path = title.replace(/[^\w\s]/gi, '')
		path = path.toLowerCase()
		path = path.replace(/ /g, "-")
		return path
	}



	// UI
	const setUI = () => {
		let ui = []
		for (let i = 0; i < collection.length; i++) {
			const link = collection[i].bestOffer.title.toLowerCase()
			const replaced = link.split(' ').join('_')

			ui.push(
				<Link className="item" key={`${i}`} onClick={() => saveScrollPosition()} to={{
					pathname: `/product/${collection[i].uID}/${getPathTitle(replaced)}`,
					state: {
						product: collection[i]
					}
				}}>
					{collection.length > 0 ? 
						<div className="item-inner-container">
							<div className="image-container">
								{collection[i].bestOffer.imageUrls ? <img src={createImageUrl(collection[i].bestOffer.imageUrls[0], 500, 500, 70)} alt={`Deal Produkt ${collection[i].bestOffer.title}`}/> : <div/>}
							</div>
							<p className="brand">{collection[i].brand}</p>
							<p className="title">{collection[i].bestOffer.title}</p>
							<p className="price">
								<span className="sale-price">{collection[i].bestOffer.salePriceStr}{collection[i].bestOffer.currency}</span>
								{" "}
								<span className="original-price">{collection[i].bestOffer.originalPriceStr}{collection[i].bestOffer.currency}</span>
							</p>
						</div>
					 : 
						<div></div>
					 }
				</Link>
			)
		}

		return ui
	}

	return(
		<div className="ProductCollection">

			{(search === undefined || search === null) && category !== "" ? 
				<Filter data={{props: props, fetchCallback: fetchCallback, showFilter: showFilter, showFilterCallback: showFilterCallback, genderColl: genderColl, fashionCategoriesColl: fashionCategoriesColl, electronicCategoriesColl: electronicCategoriesColl, setFilterCallback: setFilterCallback}}/>
			: <div></div>}

			<Header data={{type: "collection", withScoll: true, props: props, fetchCallback: fetchCallback, genderColl: genderColl, fashionCategoriesColl: fashionCategoriesColl, electronicCategoriesColl: electronicCategoriesColl, setFilterCallback: setFilterCallback, reloadCollectionCallback: reloadCollectionCallback}}/>

			<div className="centering-container">
				{search === undefined || search === null ? 
					<OptionsContainer data={{props: props, fetchCallback: fetchCallback, showFilter: showFilter, showFilterCallback: showFilterCallback}}/>
				:
					<div className="search-value-container">
						<p className="title">SUCHERGEBNIS</p>
						<p className="value">{search}</p>
					</div>
				}
				<div className="outer-container">
					{setUI()}
				</div>

				{/* Loading */}
				{ isJsonLoading ?
					<div>
						<p className="loading-text">DEALS LADEN...</p>
					</div>
				: 
					<button 
						className={`continue ${isContinueJsonLoading && "continue-loading"} ${isEndOfCollection && "continue-end"}`} 
						onClick={continueAction}>{ isContinueJsonLoading ? "LADEN..." : "WEITER"} 
					</button>
				}
			</div>

			<Footer />

			{/* <div className="background" onClick={() => console.log("...>click")}></div> */}
		</div>
	)
}

export default Products
