import React from 'react'
import {Link} from 'react-router-dom'
import "./Footer.css"

function HomeHeader(props) {


	return(
		<div className="Footer">
			<div className="link-container">
				<Link className="link" to="/contact">KONTAKT</Link>
				<Link className="link" to="/impressum">IMPRESSUM</Link>
				<Link className="link" to="/datenschutz">DATENSCHUTZ</Link>
			</div>
			<a className="instagram" href="https://www.instagram.com/deal.agent" target="_blank" rel="noreferrer"></a>
		</div>
	)
}

export default HomeHeader