import React , {useEffect, useState, useRef} from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useDispatch } from "react-redux"
import { changeShouldScroll, addScrollPosition, changeSiteNumber } from "../../Redux/actions"
// import { fetchProducts } from "../helper/apiService"
import { motion, AnimatePresence } from "framer-motion"
import { fetchSearchItems } from "../helper/apiService"
import HeaderIcon from "../../assets/Header-Icon.svg"
import SearchIcon from "../../assets/Search-Icon.svg"
import MenuButton from "./MenuButton"
import SideMenu from "./SideMenu"
import SearchOverlay from "./SearchOverlay"
import "./Header.css"

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const headerScrollVariants = {
	init: {
		padding: "43px 0",
		backgroundColor: "rgba(255,255,255,0)"
	},
	visible: {
		padding: "22px 0",
		backgroundColor: "rgba(255,255,255,1)"
	},
	initMobile: {
		padding: "27px 0",
		backgroundColor: "rgba(255,255,255,1)"
	}
}

function HomeHeader(props) {

	const dispatch = useDispatch()
	const history = useHistory()
	let query = useQuery()

	const [selectedCategory, setSelectedCategory] = useState(props.data.props ? props.data.props.match.params.category : "all")
	const [selectedSubCategory, setSubSelectedCategory] = useState(query.get("category") ? query.get("category") : "")

	// Menu
	const [showMenu, setShowMenu] = useState(false)

	// Search
	const [isSearchShown, setIsSearchShown] = useState(false)
	const [searchValue, setSearchValue] = useState("")
	const [searchProducts, setSearchProducts] = useState([])
	const [filteredSearchProducts, setFilteredSearchProducts] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const searchInput = useRef(null);

	const [scrollPosition, setScrollPosition] = useState(0);
	const handleScroll = () => {
		const position = window.pageYOffset;
		setScrollPosition(position);
	};

	const [width, setWidth]   = useState(window.innerWidth);
	// const [height, setHeight] = useState(window.innerHeight);
	const updateDimensions = () => {
		setWidth(window.innerWidth);
		// setHeight(window.innerHeight);
	}

	// Size
	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	// Scroll
	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true });
		return () => { window.removeEventListener('scroll', handleScroll); }
	}, []);

	// Deal with Params
	// const dealWithQueryParams = (key, value) => {
	// 	if (value && value.length > 0) {
	// 		if (query.get(key)) query.delete(key)
	// 		query.append(key, value)
	// 	} else {
	// 		query.delete(key)
	// 	}
	// 	history.push({search: query.toString()})
	// }


	// CALLBACK HELL
	// Fetch Callback
	// const fetchCallback = (finishWithError, res) => {
	// 	if (!finishWithError) {
	// 		props.data.fetchCallback(finishWithError, res, true)
	// 	}
	// }

	// Set Menu Callback
	const setMenuOpenCallback = (isOpen) => {
		setShowMenu(isOpen)	
	}

	// Category Callback
	const categoryCallback = (catID, subCatID, gender) => {
		setSelectedCategory(catID)
		setSubSelectedCategory(subCatID)
		if (props.data.type !== "home") window.location.href=`/collection/${catID}?category=${subCatID}${gender ? `&gender=${gender}`: ""}`
	}

	const chnageShowSearchCallback = (show) => {
		setIsSearchShown(show)
	}



	// Search Text Field Changes
	const handleSearchChange = (event) => {
		const value = event.target.value
		setSearchValue(value)

		if (value.length === 3) {
			// Fetch
			setIsLoading(true)
			fetchSearchItems(value, fetchSearchProductsCallback, 0)

		} else if (value.length < 3) {
			// Reset
			if (filterProducts.length > 0) setFilteredSearchProducts([])
			if (searchProducts.length > 0) setSearchProducts([])

		} else if (value.length > 3) {
			// Filter
			filterProducts(value)
		}

		if (width > 1080 && !isSearchShown) {
			setIsSearchShown(true)
		}
	}

	const onInputClick = () => {
		if (width > 1080 && !isSearchShown) {
			setIsSearchShown(true)
		}
	}

	const onSearchKeyDown = (target) => {
		if (target.key === "Enter" && searchValue.length > 0 ) {
            setIsSearchShown(false)
			const safeSearch = encodeURI(searchValue)
			history.push(`/search/${safeSearch}`)

			if (props.data.reloadCollectionCallback) {
				props.data.reloadCollectionCallback(searchValue, filteredSearchProducts)
			}
        }
	}

	// Fetch Search Products Callback
	const fetchSearchProductsCallback = (value, res) => {
		setIsLoading(false)
		if (res) {
			if (value.startsWith(searchValue)) {
				console.log("! SET")
				setSearchProducts(res)
				filterProducts(undefined, res)
			} else {
				console.log("! NOT SET", value, searchValue, value.startsWith(searchValue))
			}
		}
	}

	const filterProducts = (value, res) => {
		const values = value ? value.split(" ") : searchValue.split(" ")
		const products = res ? res : searchProducts

		let filtered = products.filter(product => {
			return values.every(el => product.bestOffer.title.toLowerCase().includes(el.toLowerCase()))
		})

		setFilteredSearchProducts(filtered)
	}



	// On Click
	const onLinkClick = (catID, subCatID, gender) => {
		// let newCateories = []

		// Set should scroll
		resetScrollData()

		setSelectedCategory(catID)
		setSubSelectedCategory(subCatID)

		if (!props.data.electronicCategoriesColl) {return}
		
		// fetchProducts(catID, subCatID, [], query.get("sortBy"), query.get("gender"), true,  fetchCallback)

		// if (catID === "electronic") {
		// 	const index = findIndex(subCatID, props.data.electronicCategoriesColl)
		// 	props.data.electronicCategoriesColl.forEach(element => { element.isSelected = false })
		// 	if (index)
		// 		props.data.electronicCategoriesColl[index].isSelected = !props.data.electronicCategoriesColl[index].isSelected
			
		// 	newCateories = props.data.electronicCategoriesColl
		// } else if (catID === "fashion") {
		// 	const index = findIndex(subCatID, props.data.fashionCategoriesColl)
		// 	props.data.fashionCategoriesColl.forEach(element => { element.isSelected = false })
		// 	if (index)
		// 		props.data.fashionCategoriesColl[index].isSelected = !props.data.fashionCategoriesColl[index].isSelected

		// 	newCateories = props.data.fashionCategoriesColl
		// }

		// // Query
		// const selectedCats = newCateories.filter(cat => cat.isSelected).map(cat => cat.idName)
		// dealWithQueryParams("category", selectedCats)
		if (props.data.type !== "home") window.location.href=`/collection/${catID}?category=${subCatID}${gender ? `&gender=${gender}` : ""}`
		// // Filter Callback
		// props.data.setFilterCallback(props.data.genderColl, props.data.fashionCategoriesColl, props.data.electronicCategoriesColl)
	}


	// reset redux scroll infos
	const resetScrollData = () => {
		dispatch(changeShouldScroll(false))
		dispatch(addScrollPosition(0))
		dispatch(changeSiteNumber(1))
	}


	// on search click
	const onSearchIconClick = () => {
		setIsSearchShown(!isSearchShown)
		setTimeout(() => {
			searchInput.current.focus()
		  }, 250);
	}

	// Style
	const styleLink = (catID) => {
		return {
			backgroundColor: selectedCategory === catID && props.data.withScoll ? "rgb(255, 255, 255, 1)" : "rgb(255, 255, 255, 0)",
			color: selectedCategory === catID && props.data.withScoll ? "rgb(32, 32, 32, 1)" : "rgb(32, 32, 32, 0.4)"
		}
	}

	const styleSubLink = (catID) => {
		return {
			// opacity: selectedSubCategory === catID ? "1" : "0.43"
		}
	}

	const headerStyle = () => {
		return {
			position: props.data.withScoll || width < 1080 ? "fixed" : "relative"
		}
	}


	// find
	// const findIndex = (searchValue, catArray) => {
	// 	if (catArray)
	// 	for (let index = 0; index < catArray.length; index++) {
	// 		if (searchValue === catArray[index].idName) return index
	// 	}
	// }



	// Fashion Dorop
	const genderDropDown = (gender) => {
		if (props.data.type !== "home") {
			return (
				<div className="dropdown-content">
					<p className="gender-title">{gender === "men" ? "HERREN" : "DAMEN"}</p>
					<button className="subLink" onClick={() => onLinkClick("fashion", "all", gender)}> 
						<p style={styleSubLink("all")}>ALLE</p> 
					</button>
					<button className="subLink" onClick={() => onLinkClick("fashion", "sneaker", gender)}> 
						<p style={styleSubLink("sneaker")}>SNEAKER</p> 
					</button>
					<button className="subLink" onClick={() => onLinkClick("fashion", "clothing", gender)}> 
						<p style={styleSubLink("clothing")}>KLEIDUNG</p> 
					</button>
				</div>
			)
		} else {
			return (
				<div className="dropdown-content">
					<p className="gender-title">{gender === "men" ? "HERREN" : "DAMEN"}</p>
					<Link className="subLink" to="/collection/fashion?category=all" onClick={() => onLinkClick("fashion", "all", gender)}> 
						<p style={styleSubLink("all")}>ALLE</p> 
					</Link>
					<Link className="subLink" to="/collection/fashion?category=sneaker" onClick={() => onLinkClick("fashion", "sneaker", gender)}> 
						<p style={styleSubLink("sneaker")}>SNEAKER</p> 
					</Link>
					<Link className="subLink" to="/collection/fashion?category=clothing" onClick={() => onLinkClick("fashion", "clothing", gender)}> 
						<p style={styleSubLink("clothing")}>KLEIDUNG</p> 
					</Link>
				</div>
			)
		}
	}



	// DROPDOWN
	const catDrop = (category) => {
		let categories
		if (category === "electronic") {
			if (props.data.type !== "home") {
				categories = (
					<div className="dropdown-content">
						{/* Before it was with a instead of button maybe a bug in future */}
						<button className="subLink" onClick={() => onLinkClick("electronic", "all")}> 
							<p style={styleSubLink("all")}>ALLE</p> 
						</button>
						<button className="subLink" onClick={() => onLinkClick("electronic", "smartphone")}> 
							<p style={styleSubLink("smartphone")}>SMARTPHONES</p> 
						</button>
						<button className="subLink" onClick={() => onLinkClick("electronic", "tv")}> 
							<p style={styleSubLink("tv")}>TV</p> 
						</button>
						<button className="subLink" onClick={() => onLinkClick("electronic", "headphones")}> 
							<p style={styleSubLink("headphones")}>HEADPHONES</p> 
						</button>
						<button className="subLink" onClick={() => onLinkClick("electronic", "computer")}> 
							<p style={styleSubLink("computer")}>COMPUTER</p> 
						</button>
						<button className="subLink" onClick={() => onLinkClick("electronic", "gaming")}> 
							<p style={styleSubLink("gaming")}>GAMING</p> 
						</button>
						<button className="subLink" onClick={() => onLinkClick("electronic", "monitor")}> 
							<p style={styleSubLink("monitor")}>MONITOR</p> 	
						</button>
						<button className="subLink" onClick={() => onLinkClick("electronic", "tablet")}> 
							<p style={styleSubLink("tablet")}>TABLET</p> 
						</button>
					</div>
				)
			} else {
				categories = (
					<div className="dropdown-content">
						<Link className="subLink" to="/collection/electronic?category=all" onClick={() => onLinkClick("electronic", "all", 0)}> 
							<p style={styleSubLink("all")}>ALLE</p> 
						</Link>
						<Link className="subLink" to="/collection/electronic?category=smartphone" onClick={() => onLinkClick("electronic", "smartphone")}> 
							<p style={styleSubLink("smartphone")}>SMARTPHONES</p> 
						</Link>
						<Link className="subLink" to="/collection/electronic?category=tv" onClick={() => onLinkClick("electronic", "tv")}> 
							<p style={styleSubLink("tv")}>TV</p> 
						</Link>
						<Link className="subLink" to="/collection/electronic?category=headphones" onClick={() => onLinkClick("electronic", "headphones")}> 
							<p style={styleSubLink("headphones")}>HEADPHONES</p> 
						</Link>
						<Link className="subLink" to="/collection/electronic?category=computer" onClick={() => onLinkClick("electronic", "computer")}> 
							<p style={styleSubLink("computer")}>COMPUTER</p> 
						</Link>
						<Link className="subLink" to="/collection/electronic?category=gaming" onClick={() => onLinkClick("electronic", "gaming")}> 
							<p style={styleSubLink("gaming")}>GAMING</p> 
						</Link>
						<Link className="subLink" to="/collection/electronic?category=monitor" onClick={() => onLinkClick("electronic", "monitor")}> 
							<p style={styleSubLink("monitor")}>MONITOR</p> 
						</Link>
						<Link className="subLink" to="/collection/electronic?category=tablet" onClick={() => onLinkClick("electronic", "tablet")}> 
							<p style={styleSubLink("tablet")}>TABLET</p> 
						</Link>
					</div>
				)	
			}
	
		} else if (category === "fashion") {
			// FASHION
			categories = (
				<div className="dropdown-h-container">
					{genderDropDown("women")}
					{genderDropDown("men")}
				</div>
			)
		}

		return (	
			<div className="dropdown">
				<Link className="link" to={`/collection/${category}`} onClick={() => onLinkClick(category, "all")}>
					<motion.p whileHover={{ backgroundColor: "rgb(255, 255, 255, 1)", color: "rgb(32, 32, 32, 1)" }} style={styleLink(category)}>{category}</motion.p>
				</Link>
				<div className="dropdown-cover-content">
					{categories}
				</div>
			</div>
		)
	}



	return(
		<div className="HomeHeader" style={headerStyle()}>
			<motion.div className="header-container" variants={headerScrollVariants} initial={width < 1080 ? "initMobile" : "init"} animate={width < 1080 ? "initMobile" : scrollPosition > 30 && props.data.withScoll ? "visible" : "init"} transition={{bounce: 0}}>
				<div className="bottom-container">
					<div className="nav-logo-wrapper">
						<Link to="/" className="nav-logo" onClick={() => resetScrollData()}>
							<div className="title-container">
								<img src={HeaderIcon} alt="Deal Agent Icon" />
								{width > 1080 && <h3>DEAL AGENT</h3>}
							</div>
						</Link>
					</div>

					{
						width > 1080 ?
							<div className="links-container">
								<Link className="link" to={{pathname: "/collection/all"}} onClick={() => onLinkClick("all", "all")}>
									<motion.p whileHover={{ backgroundColor: "rgb(255, 255, 255, 1)", color: "rgb(32, 32, 32, 1)" }} transition={{ default: { duration: 0.1 } }} style={styleLink("all")}>ALLE</motion.p>
								</Link>
								{catDrop("electronic")}
								{/* {catDrop("fashion")} */}
							</div>
						:
							<div className="menu-icon">		
								<MenuButton data={{showMenu: showMenu, setMenuOpenCallback: setMenuOpenCallback}}/>
							</div>
					}

					{width < 1080 && <SideMenu data={{showMenu: showMenu, pageType: props.data.type, setMenuOpenCallback: setMenuOpenCallback, categoryCallback: categoryCallback}}/>}
					
					{width > 1080 ? 
						<input type="text" className="link-input" placeholder="Suchen" value={searchValue} onChange={handleSearchChange} onClick={onInputClick} onKeyDown={onSearchKeyDown} ref={searchInput}/> 
					: 
						<button className="search-icon-button" onClick={() => onSearchIconClick()}>
							<img src={SearchIcon} alt="search icon"/>
						</button>
					}
				</div>
			</motion.div>	


			{/* Mobile Search */}
			<AnimatePresence>
				{width < 1080 && isSearchShown && 
					<motion.div className="search-mobile-container" initial={{y: -60}} animate={{y: 0}} exit={{y: -60}} transition={{ease: [0.77, 0, 0.175, 1]}}>
						<div className="mobile-search-field">
							<img src={SearchIcon} alt="mobile search icon" />
							<input type="text" className="mobile-search-input" placeholder="Suchen" value={searchValue} onChange={handleSearchChange} onKeyDown={onSearchKeyDown} ref={searchInput} />
						</div>
						<button className="cancel-search" onClick={() => setIsSearchShown(!isSearchShown)}>Abbrechen</button>
					</motion.div>
				}
			</AnimatePresence>


			{/* Search Overlay */}
			<AnimatePresence>
				{
					isSearchShown && <SearchOverlay data={{products: filteredSearchProducts, isSearchShown: isSearchShown, searchText: searchValue, isLoading: isLoading, reloadCollectionCallback: props.data.reloadCollectionCallback, chnageShowSearchCallback: chnageShowSearchCallback}}/>
				}
			</AnimatePresence>
		</div>
	)
}

export default HomeHeader