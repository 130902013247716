


const shouldScrollReducer = (state = false, action) => {
	switch(action.type) {
		case "SHOULD_SCROLL":
			return action.payload
		default: 
			return state
	}
}

export default shouldScrollReducer