import React , {useEffect} from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useHistory } from "react-router-dom"
// import { useSelector, useDispatch } from "react-redux"
// import { change, changeSiteNumber, addHomeProductsJson } from "../../../Redux/actions"
import { fetchProducts } from "../../helper/apiService"
import { motion, AnimatePresence } from "framer-motion"
import "./Filter.css"

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

function Filter(props) {

	// 
	const history = useHistory()
	let query = useQuery()
	// const [sortBy, setSortBy] = useState(query.get("sortBy"))
	// const [fix, setFix] = useState(0)



	useEffect(() => {
	},[])


	// Deal with Params
	const dealWithQueryParams = (key, value) => {
		if (value && value.length > 0) {
			if (query.get(key)) query.delete(key)
			query.append(key, value)
		} else {
			query.delete(key)
		}
		history.push({search: query.toString()})
	}


	// Fetch Callback
	const fetchCallback = (finishWithError, res) => {
		console.log("! Callbck")
		if (!finishWithError) {
			props.data.fetchCallback(finishWithError, res, true)
		}
	}


	//
	const selectCatAct = (index, isGender) => {
		let cat = props.data.props.match ? props.data.props.match.params.category : ""
		const catStrArr = cat.split("?")
		cat = catStrArr[0]
		let newCats = []

		console.log("cat.", cat)

		if (isGender) {
			props.data.genderColl[index].isSelected = !props.data.genderColl[index].isSelected
			newCats = props.data.genderColl

		} else if (cat === "fashion") {
			props.data.fashionCategoriesColl[index].isSelected = !props.data.fashionCategoriesColl[index].isSelected
			newCats = props.data.fashionCategoriesColl

		} else if (cat === "electronic") {
			props.data.electronicCategoriesColl[index].isSelected = !props.data.electronicCategoriesColl[index].isSelected
			newCats = props.data.electronicCategoriesColl
		}

		// setFix(fix + 1)

		// set query
		const selectedCats = newCats.filter(cat => cat.isSelected).map(cat => cat.idName)
		dealWithQueryParams(isGender ? "gender" : "category", selectedCats)

		// fetch
		fetchProducts(cat, query.get("category"), [], query.get("sortBy"), query.get("gender"), true,  fetchCallback)

		//
		console.log("before", props.data.electronicCategoriesColl)
		props.data.setFilterCallback(props.data.genderColl, props.data.fashionCategoriesColl, props.data.electronicCategoriesColl)
	}


	// dismiss
	const dismiss = () => {
		props.data.showFilterCallback(false)
	}


	// Styles
	const selectCircle = (isSelected) => {
		return {
			backgroundColor: isSelected ? "#202020" : "rgba(0,0,0,0)",
			border: isSelected ? "" : "2px solid #979797"
		}
	}


	// UI
	// CATEGORY SELCTION
	const categories = () => {
		let ui = []
		let cat = props.data.props.match ? props.data.props.match.params.category : ""
		const catStrArr = cat.split("?")
		cat = catStrArr[0]

		let array = []


		if (cat === "fashion") array = props.data.fashionCategoriesColl
		else if (cat === "electronic") array = props.data.electronicCategoriesColl
		else if (cat === "all") array = []

		for (let i = 0; i < array.length; i++) {
			ui.push(
				<button onClick={() => selectCatAct(i)}>
					<div className="select-cat" style={selectCircle(array[i].isSelected)}></div>
					<p>{array[i].name}</p>
				</button>
			)
		}

		return (
			<div className="category-container">
				{ui}
			</div>
		)
	}

	// GENDER SELCTION
	const chooseGender = () => {
		let cat = props.data.props.match ? props.data.props.match.params.category : ""
		const catStrArr = cat.split("?")
		cat = catStrArr[0]
		if (cat !== "fashion") return

		let ui = []

		for (let i = 0; i < props.data.genderColl.length; i++) {
			ui.push(
				<button onClick={() => selectCatAct(i, true)}>
					<div className="select-cat" style={selectCircle(props.data.genderColl[i].isSelected)}></div>
					<p>{props.data.genderColl[i].name}</p>
				</button>
			)
		}

		return (
			<div className="category-container gender-c">
				{ui}
			</div>
		)
	}


	return(
		<div className="Filter">
			<motion.div initial={{x: -410}} animate={{x: props.data.showFilter ? 0 : -410}} transition={{ease: [0.77, 0, 0.175, 1]}} className="side-container">
				{categories()}
				{chooseGender()}
			</motion.div>

			<AnimatePresence>
				{ props.data.showFilter && <motion.div initial={{opacity: 0}} animate={{opacity: 0.5}} exit={{opacity: 0}} className="overlay" onClick={dismiss}></motion.div>  }
			</AnimatePresence>
		</div>
	)
}

export default Filter