import React from "react"
// import { useState } from "react"
import { useDispatch } from "react-redux"
import { changeShouldScroll, addScrollPosition, changeSiteNumber } from "../../Redux/actions"
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"
import { createImageUrl } from "../helper/createImgUrl"
import "./SearchOverlay.css"

const SearchOverlay = (props) => {

	const dispatch = useDispatch()

	// reset redux scroll infos
	const resetScrollData = () => {
		dispatch(changeShouldScroll(false))
		dispatch(addScrollPosition(0))
		dispatch(changeSiteNumber(1))
	}

	const getPathTitle = (title) => {
		let path = title.replace(/[^\w\s]/gi, '')
		path = path.toLowerCase()
		path = path.replace(/ /g, "-")
		return path
	}


	const encodeURL = () => {
		return encodeURI(`/search/${props.data.searchText}`)
	}

	//
	const allClick = () => {
		resetScrollData()
		props.data.chnageShowSearchCallback(false)
		if (props.data.reloadCollectionCallback) {
			props.data.reloadCollectionCallback(props.data.searchText, props.data.products)
		}
	}

	const itemsUI = () => {
		const items = []
		const res = props.data.products
		const length = res.length > 7 ? 7 : res.length

		if (length === 0 && !props.data.isLoading) {
			return (
				<div className="center-container">
					<p>Es wurden keine Deals gefunden.</p>
				</div>
			)
		}

		for (let i = 0; i < length; i++) {
			items.push(
				<Link to={{pathname: `/product/${res[i].uID}/${getPathTitle(res[i].bestOffer.title)}`, state: "flushSS"}} className="item-container" key={`${res[i].title}, ${i}`} onClick={() => resetScrollData()}>
					<img src={createImageUrl(res[i].bestOffer.imageUrls[0], 90 * window.devicePixelRatio, 90 * window.devicePixelRatio, 65)} alt={`search item ${res[i].title}`} />
					<div className="info-search-container"> 
						<p className="brand">{res[i].brand}</p>
						<p className="title">{res[i].bestOffer.title}</p>
						<p className="price">
							<span className="sale-price">{res[i].bestOffer.salePriceStr}{res[i].bestOffer.currency}</span>
							<span className="original-price">{res[i].bestOffer.originalPriceStr}{res[i].bestOffer.currency}</span>
						</p>
					</div>
				</Link>
			)
		}

		return items
	}


	return (
		<motion.div className="search-overlay" initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 0.16}} exit={{opacity: 0}}>

			{/* MIN ZEICHEN */}
			{ props.data.searchText.length < 3 ?
				<div className="center-container">
					<p>Bitte min. 2 Zeichen eingeben</p>
				</div>
			: itemsUI() }
			
			{/* MORE BUTTON */}
			{ props.data.searchText.length >= 3 && !props.data.isLoading ?
				<Link to={{ 
					pathname: encodeURL(),
					state: {searchCollection: props.data.products}
				}} className="all-res-button" onClick={() => allClick()}>
					<p>ALLE ERGEBNISSE</p>
				</Link>
			: <div></div> }

			{/* Loading */}
			{ props.data.isLoading ?
				<div className="center-container">
					<p>Deals laden...</p>
				</div>
			: <div></div>}
		</motion.div>
	)
}

export default SearchOverlay
