export const createImageUrl = (url, width, height, quality) => {
	if (url === undefined) return
	const newUrl = url.split("/upload/")

	const part1 = newUrl[0]
	const part3 = newUrl[1]

	let part2 = "/upload/"
	if (width !== undefined) { part2 = `/upload/c_scale,w_${width},q_${quality}/` }
	else if (height !== undefined) { part2 = `/upload/c_scale,h_${height},q_${quality}/` }

	return part1 + part2 + part3
}