import React , {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import "./SideCollection.css"
import {addHomeNewestJson} from "../../../Redux/actions"
import { useSelector, useDispatch } from "react-redux"
import LinkIcon from "../../../assets/link-icon.svg"
import NavLeft from "../../../assets/Nav-Left.svg"
import NavRight from "../../../assets/Nav-Right.svg"
// import { animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
// import * as Scroll from 'react-scroll';
import { useRef } from 'react'
import smoothscroll from 'smoothscroll-polyfill';

// kick off the polyfill!
smoothscroll.polyfill();

function Products(props) {

	const [collection, setCollection] = useState([])
	const [isJsonLoading, setIsJsonLoading] = useState(false)
	const [isFetchErr, setIsFetchErr] = useState(false)
	// const [scrollPosition, setScrollPosition] = useState(0)

	// Scroll Div
	const hScrollViewRef = useRef()

	// Redux
	const savedNewestHomeProducts = useSelector(state => state.homeNewestProducts)
	const dispatch = useDispatch()

	// Scroll
	const executeScroll = (position) => {
		const El = document.getElementById(`H-Home-Collection-${props.state.sectionID}`);
		El.scrollTo({left: position, behavior: 'smooth'});
	}


	useEffect(() => {
		if (props.state.type === "home-newest") { 
			if (savedNewestHomeProducts === 0) { fetchItems() }
			else { setJsonCollection(savedNewestHomeProducts) }
		} else if (props.state.type === "sneaker-newest") {
			fetchItems()
		} else if (props.state.type === "smartphone-newest") {
			fetchItems()
		} else {
			fetchItems()
		}

		// eslint-disable-next-line 
	}, [savedNewestHomeProducts])


	const setJsonCollection = (collection) => {
		setIsJsonLoading(false)
		setCollection(collection)
	}


	useEffect(() => {
		// fetchItems()
		// eslint-disable-next-line
	}, [])





	// FETCH JSON
	const fetchItems = () => {
		if (isJsonLoading) return
		setIsJsonLoading(true)

		let categoryIDs = props.state.uIDs

		// fetch(`http://localhost:3000/products/best/time=today/limit=10`, {
		fetch(`https://deal-friday.herokuapp.com/products/best/time=today/limit=10`, {
			method: 'post',
			headers: {'Content-Type':'application/json', 'x-api-key': '38A4j9JS0sw7hw9js90jw72o9GJutS2Nrw2Ks6W'},
			body: JSON.stringify({
				"categoryIDs": categoryIDs,
				"productIDs": []
			})
		})
		.then((response) => response.json())
		.then((responseJson) => {
			setCollection(responseJson)
			if (props.state.type === "home-newest") {
				dispatch(addHomeNewestJson(responseJson))
			}
		})
		.catch((error) => {
			setIsJsonLoading(false)
			setIsFetchErr(true)
			console.error("failed to load products", error)
		})
	}



	const scroll = (forward) => {
		const xPos = hScrollViewRef.current.getBoundingClientRect().x * -1
		let scrollWay = 1400

		if (window.innerWidth < 1600) {
			scrollWay = window.innerWidth
		}

		const newPosition = forward ? xPos + scrollWay : xPos - scrollWay
		executeScroll(newPosition)
	}



	const getPathTitle = (title) => {
		let path = title.replace(/[^\w\s]/gi, '')
		path = path.toLowerCase()
		path = path.replace(/ /g, "-")
		return path
	}

	// UI
	const setUI = () => {
		let ui = []
		const limit = collection.length === 0 ? 10 : collection.length

		for (let i = 0; i < limit; i++) {
			let obj

			if (collection[i]) {
				const link = collection[i].bestOffer.title.toLowerCase()
				const replaced = link.split(' ').join('_')
				obj = {
					pathname: `/product/${collection[i].uID}/${getPathTitle(replaced)}`,
					state: { data: collection[i] }
				}
			} else {
				obj = { state: { data: collection[i] } }
			}

			ui.push(
				<Link className="item" key={`${i}`} to={obj}>
					{collection.length > 0 ? 
						<div className="item-inner-container">
							<div className="image-container">
								{collection[i].bestOffer.imageUrls ? <img src={collection[i].bestOffer.imageUrls[0]} alt={collection[i].bestOffer.title}/> : <div/>}
							</div>
							<p className="brand">{collection[i].brand}</p>
							<p className="title">{collection[i].bestOffer.title}</p>
							<p className="price">
								<span className="sale-price">{collection[i].bestOffer.salePriceStr}{collection[i].bestOffer.currency}</span>
								<span className="original-price">{collection[i].bestOffer.originalPriceStr}{collection[i].bestOffer.currency}</span>
							</p>
						</div>
					 : 
						<div></div>
					 }
				</Link>
			)
		}

		return ui
	}

	return(
		<div className="H-Home-Collection-Outer">
			<Link className="newest-link" to={props.state ? `collection/${props.state.pathName1}` : "404" }>
				<p className="newest">Neuste Deals</p>
				<img className="link-icon" src={LinkIcon} alt={`link to ${props.state.pathName1}`}/>
			</Link>

			<button className="nav-arrrow nav-left" onClick={() => scroll(true)}>
				<img src={NavRight} alt="right-nav"/>
			</button>
			<button className="nav-arrrow nav-right" onClick={() => scroll(false)}>
				<img src={NavLeft} alt="left-nav"/>
			</button>
			
			<div className="H-Home-Collection" id={`H-Home-Collection-${props.state.sectionID}`}>
				<div className="outer-container" ref={hScrollViewRef}>
					{setUI()}
				</div>
			</div>
		</div>
	)
}

export default Products