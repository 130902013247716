import {React, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Footer from "../footer/Footer"
import Header from "../header/Header"
import "./404.css"

function Page404() {

	useEffect(() => {
		document.title = "404 - Seite nicht gefunden"
	}, [])

	return(
		<div className="Page404">
			<Header data={{type: "home"}}/>

			<div className="wrapper">
				{/* <h1><span className="code">404</span> SEITE NICHT GEFUNDEN</h1> */}
				<h1>SEITE NICHT GEFUNDEN</h1>
				<h2>Die Seite zu diesem Link existiert nicht, wurde umgezogen oder gelöscht.</h2>
				<Link className="back" to="/">STARTSEITE</Link>
			</div>

			<Footer />
		</div>
	)
}

export default Page404