import React, {useEffect, useState } from 'react'
import "./ProductPage.css"
import {Link, useHistory} from 'react-router-dom'
import Footer from "../footer/Footer"
import Header from "../header/Header"
import { fetchSingleProduct } from "../helper/apiService"

function ProductPage(props) {

	const history = useHistory()

	const [product, setProduct] = useState(props.location.state ? props.location.state.product : undefined)
	const [isLoading, setIsLoading] = useState(false)

	// const [scrollPosition, setScrollPosition] = useState(0);

	// scr
	const executeScroll = position => {window.scrollTo(0, position)}

	useEffect(() => {
		// Fetch Product
		if (!product && !isLoading) {
			setIsLoading(true)
			fetchSingleProduct(props.match.params.productUID, fetchCallback)
		}

		if (product && product.bestOffer.title && product.bestOffer.title !== "") {
			document.title = product.bestOffer.title
		}

		executeScroll(0)
	}, [])



	// Fetch Callback
	const fetchCallback = (finishWithError, res, reset) => {
		if (finishWithError) {
			history.push(`/404`)
		} else {
			setProduct(res)
			document.title = res.bestOffer.title
		}
		setIsLoading(false)


	}


	// Description
	const descriptionSection = () => {
		let ui = []

		if (product.bestOffer.sellingPoints) {
			for (let i = 0; i < product.bestOffer.sellingPoints.length; i++) {
				ui.push(
					<div className="selling-point-wrapper" key={`selling point ${i}`}>
						<div className="dot"></div>
						<p>{product.bestOffer.sellingPoints[i]}</p>
					</div>
				)
			}
		}

		if ((product.bestOffer.description && product.bestOffer.description !== "") || (product.bestOffer.sellingPoints && product.bestOffer.sellingPoints.length > 0))
		return  (
			<div className="info-section">
				<div className="seperate-line"></div>
				{product.bestOffer.description && <p className="description" style={{marginBottom: product.bestOffer.sellingPoints.length > 0 ? "50px" : "0"}}>{product.bestOffer.description}</p>}
				{ui}
			</div>
		)
	}

	// Shop info section
	const shopInfoSection = () => {
		let ui = []

		if (product.bestOffer.sizes) {
			for (let i = 0; i < product.bestOffer.sizes.length; i++) {
				ui.push(
					<p key={product.sizes[i]}>{product.sizes[i]}</p>
				)
			}
		}

		if ((product.bestOffer.description && product.bestOffer.description !== "") || (product.bestOffer.sellingPoints && product.bestOffer.sellingPoints.length > 0))
		return  (
			<div className="info-section">
				<div className="seperate-line"></div>
				<div className="info-wrapper">
					<div className="info-v-stack">
						<p className="title">SHOP</p>
						<p className="name">{product.bestOffer.shopName}</p>
					</div>
					{ product.bestOffer.sizes.length > 0 &&
						<div className="info-v-stack">
							<p className="title">SIZES</p>
							<div className="size-container">
								{ui}
							</div>
						</div>
					}
				</div>
			</div>
		)
	}


	const content = () => {
		if (isLoading) {
			return (
				<div>
					{/* <h2>Laden..</h2> */}
				</div>
			)
		} else if (product !== undefined && product !== null) {
			return (
				<div className="content-split">
					<div className="image-container">
						<img src={product ? product.bestOffer.imageUrls[0] : ""} alt="Product"/>
					</div>

					<div className="product-info-container">
						<p className="brand">{product.brand}</p>
						<h1 className="title">{product.bestOffer.title}</h1>
						<p className="price">
							<span className="sale-price">{`${product.bestOffer.salePriceStr} ${product.bestOffer.currency}`}</span>
							<span className="original-price">{`${product.bestOffer.originalPriceStr} ${product.bestOffer.currency}`}</span>
						</p>
						<a className="deal-button" href={product.bestOffer.url} target="_blank" rel="noopener noreferrer">ZUM DEAL</a>

						{}

						{shopInfoSection()}
						{descriptionSection()}
					</div>
				</div>
			)
		}
	}


	return(
		<div className="product-page">
			<Header data={{type: "home", withScoll: true}}/>

			{content()}

			<Footer />
		</div>
	)
}

export default ProductPage