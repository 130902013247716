
// 
export function fetchProducts(category, subCategories, existingCollection, sortBy, gender, reset, callback, siteNumber) {
		// console.log("--> FETCH", "categories", category, "existingCollection", existingCollection, "sortBy", sortBy, "subCategories", subCategories, "siteNumber:", siteNumber)

		// Sub Cats
		const subCategoriesArray = subCategories ? subCategories.split(',') : ["all"]
		const genderArray = gender ? gender.split(',') : ["men", "women"]
		// console.log("subCategoriesArray", subCategoriesArray, "/ genderArray", genderArray)
	
		// Create Server Category
		let finalCats = []
		if (category === "all") {
			finalCats = ["all"]

		} else if (category === "fashion") {
			if (subCategoriesArray && subCategoriesArray.length > 0) {
				for (let i = 0; i < subCategoriesArray.length; i++) {
					if (gender) {
						for (let iGender = 0; iGender < genderArray.length; iGender++) {
							finalCats.push(`f-${genderArray[iGender]}-${subCategoriesArray[i].toLowerCase()}`)
						}
					} else {
						finalCats.push(`f-men-${subCategoriesArray[i].toLowerCase()}`)
						finalCats.push(`f-women-${subCategoriesArray[i].toLowerCase()}`)
					}
				}
			} else {
				if (gender) {
					for (let genderI = 0; genderI < genderArray.length; genderI++) {
						finalCats = [`f-${genderArray[genderI]}-all`]	
					}
				} else finalCats = [`f-men-all`, `f-women-all`]
			}

		} else if (category === "electronic") {
			if (subCategoriesArray && subCategoriesArray.length > 0) {
				for (let i = 0; i < subCategoriesArray.length; i++) {
					finalCats.push(`e-${subCategoriesArray[i].toLowerCase()}`)
				}
			} else {
				finalCats = [`e-all`]
			}
		}

		// console.log("finalCats", finalCats)

		let productIDs = []
		if (existingCollection && existingCollection.length > 0) {
			productIDs = existingCollection.map(product => product.uID)
		}

		let limit = 24
		if (siteNumber && siteNumber > 0) limit = limit * siteNumber
		// const path = sortBy === "neuste" ? `http://localhost:3000/products/sortBy=newest-4-real/limit=${limit}` : `http://localhost:3000/products/sortBy=relevanz/limit=${limit}`
		const path = sortBy === "neuste" ? `https://deal-friday.herokuapp.com/products/sortBy=newest/limit=${limit}` : `https://deal-friday.herokuapp.com/products/sortBy=relevanz/limit=${limit}`

		fetch(path, {
			method: 'post',
			headers: {'Content-Type':'application/json', 'x-api-key': '38A4j9JS0sw7hw9js90jw72o9GJutS2Nrw2Ks6W'},
			body: JSON.stringify({
				// "categoryIDs": categories,
				"categoryIDs": finalCats,
				"productIDs": productIDs
			})
		})
		.then((response) => response.json())
		.then((responseJson) => {
			if(callback) callback(false, responseJson, reset)
		})
		.catch((error) => {
			if(callback) callback(true)
			console.error("failed to load products", error)
		})
}



// FETCH Single product
export function fetchSingleProduct(uID, callback) {
	// const path = `http://localhost:3000/products/product=${uID}`
	const path = `https://deal-friday.herokuapp.com/products/product=${uID}`

	fetch(path, {
		method: 'get',
		headers: {'Content-Type':'application/json', 'x-api-key': '38A4j9JS0sw7hw9js90jw72o9GJutS2Nrw2Ks6W'},
	})
	.then((response) => response.json())
	.then((responseJson) => {
		if(callback) callback(false, responseJson)
	})
	.catch((error) => {
		if(callback) callback(true)
		console.error("failed to load products", error)
	})
}



export function fetchSearchItems(searchValue, callback, limit, existingProducts, override) {
	const safePath = encodeURI(searchValue)
	// const path = `http://localhost:3000/products/search=${safePath}/limit=${limit}`
	const path = `https://deal-friday.herokuapp.com/products/search=${safePath}/limit=${limit}`

	let productIDs = []
	if (existingProducts) {
		productIDs = existingProducts.map(product => product.uID)
	}

	fetch(path, {
		method: 'post',
		headers: {'Content-Type':'application/json', 'x-api-key': '38A4j9JS0sw7hw9js90jw72o9GJutS2Nrw2Ks6W'},
		body: JSON.stringify({
			"productIDs": productIDs
		})
		
	})
	.then((response) => response.json())
	.then((responseJson) => {
		if(callback) callback(searchValue, responseJson, override)
	})
	.catch((error) => {
		if(callback) callback(searchValue, undefined)
		console.error("failed to load search items", error)
	})
}