import {React, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Footer from "../footer/Footer"
import Header from "../header/Header"
import "./Contact.css"

function Contact(props) {

	// Scroll
	const executeScroll = position => {window.scrollTo(0, position)}

	useEffect(() => {
		document.title = "Kontakt"
		executeScroll(0)
	}, [])

	return(
		<div className="contact">
			<Header data={{type: "home"}}/>

			<div className="contact-wrapper">
				<h1>KONTAKT</h1>
				<a href="mailto:hello@deal-agent.com">hello@deal-agent.com</a>
			</div>

			<Footer />
		</div>
	)
}

export default Contact