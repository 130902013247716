import React , {useEffect, useState} from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
// import {change, changeSiteNumber, addHomeProductsJson} from "../../../Redux/actions"
import "./OptionsContainer.css"
import FilterIcon from "../../../assets/filter.svg"
import {fetchProducts} from "../../helper/apiService"

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

function OptionsContainer(props) {

	// 
	const history = useHistory()
	let query = useQuery()
	const [sortBy, setSortBy] = useState(query.get("sortBy"))

	// Redux
	// const savedLastProductCollection = useSelector(state => state.homeProductsApi)
	// const dispatch = useDispatch()

	// Filter
	// const [showFilter, setShowFilter] = useState(false)

	useEffect(() => {
	},[])

	// Deal with Params
	const dealWithQueryParams = (value) => {
		if (value) {
			if (query.get("sortBy")) query.delete("sortBy")
			query.append("sortBy", value)
		} else {
			query.delete("sortBy")
		}
		history.push({search: query.toString()})
	}


	// Fetch Callback
	const fetchCallback = (finishWithError, res) => {
		if (!finishWithError) {
			props.data.fetchCallback(finishWithError, res, true)
		}
	}


	// Sort Action
	const selectAction = (value) => {
		const cat = props.data.props.match ? props.data.props.match.params.category : ""
		setSortBy(value)
		fetchProducts(cat, query.get("category"), [], value, query.get("gender"), true,  fetchCallback)
		dealWithQueryParams(value)
	}


	// Filter Act
	const filterAction = () => {
	 	props.data.showFilterCallback(!props.data.showFilter)
	}


	return(
		<div className="OptionContainer">
			<button className="filter-container" onClick={() => filterAction()}>
				<img src={FilterIcon} alt="Filter"/>
				<p>Filter</p>
			</button>
			<div className="sortBy-container">
				<p>Sort By</p>
				<select value={sortBy} onChange={e => selectAction(e.target.value)}>
					<option value="relevanz">Relevanz</option>
					<option value="neuste">Neuste</option>
				</select>
			</div>
		</div>
	)
}

export default OptionsContainer