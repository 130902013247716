import React , {useEffect} from 'react'
import "./Home.css"
import Header from "../header/Header"
import SideCollection from "../products/sideCollection/SideCollection"
import CategorySection from "./categorySection/CategorySection"
import Footer from "../footer/Footer"

function Home() {

	// Scroll
	const executeScroll = position => {window.scrollTo(0, position)}

	useEffect(() => {
		executeScroll(0)
		document.title = "Deal Agent - Schnäppchen, Deals und Angebote"
	},[])




	return(
		<div className="Home">
			<Header data={{type: "home", withScoll: false}}/>
			
			<SideCollection state={{type: "home-newest", uIDs: ["all"], sectionID: 1, pathName1: "all?sortBy=neuste"}}/>
			
			{/* <CategorySection data={{catName: "sneaker", catID: "sneaker", pathName: "fashion?category=sneaker"}}/>
			<SideCollection state={{type: "sneaker-newest", uIDs: ["f-men-sneaker", "f-women-sneaker"], sectionID: 2, pathName1: "fashion?category=sneaker&sortBy=neuste"}}/> */}

			<CategorySection data={{catName: "kopfhörer", catID: "headphones", pathName: "electronic?category=headphones"}}/>
			<SideCollection state={{type: "headphones-newest", uIDs: ["e-headphones"], sectionID: 2, pathName1: "electronic?category=headphones&sortBy=neuste"}}/>

			<CategorySection data={{catName: "gaming", catID: "gaming", pathName: "electronic?category=gaming"}}/>
			<SideCollection state={{type: "gaming-newest", uIDs: ["e-gaming"], sectionID: 3, pathName1: "electronic?category=gaming&sortBy=neuste"}}/>

			<CategorySection data={{catName: "smartphone", catID: "smartphone", pathName: "electronic?category=smartphone"}}/>
			<SideCollection state={{type: "smartphone-newest", uIDs: ["e-smartphone"], sectionID: 4, pathName1: "electronic?category=smartphone&sortBy=neuste"}}/>

			<CategorySection data={{catName: "tablet", catID: "tablet", pathName: "electronic?category=tablet"}}/>
			<SideCollection state={{type: "tablet-newest", uIDs: ["e-tablet"], sectionID: 5, pathName1: "electronic?category=tablet&sortBy=neuste"}}/>

			<CategorySection data={{catName: "monitor", catID: "monitor", pathName: "electronic?category=monitor"}}/>
			<SideCollection state={{type: "monitor-newest", uIDs: ["e-monitor"], sectionID: 6, pathName1: "electronic?category=monitor&sortBy=neuste"}}/>

			<CategorySection data={{catName: "smartwatch", catID: "smartwatch", pathName: "electronic?category=smartwatch"}}/>
			<SideCollection state={{type: "smartwatch-smartwatch", uIDs: ["e-smartwatch"], sectionID: 7, pathName1: "electronic?category=smartwatch&sortBy=neuste"}}/>

			<CategorySection data={{catName: "computer", catID: "computer", pathName: "electronic?category=computer"}}/>
			<SideCollection state={{type: "computer-newest", uIDs: ["e-computer"], sectionID: 8, pathName1: "electronic?category=computer&sortBy=neuste"}}/>

			<CategorySection data={{catName: "tv", catID: "tv", pathName: "electronic?category=tv"}}/>
			<SideCollection state={{type: "tv-newest", uIDs: ["e-tv"], sectionID: 8, pathName1: "electronic?category=tv&sortBy=neuste"}}/>

			<CategorySection data={{catName: "kamara", catID: "camara", pathName: "electronic?category=camara"}}/>
			<SideCollection state={{type: "camara-newest", uIDs: ["e-camara"], sectionID: 8, pathName1: "electronic?category=camara&sortBy=neuste"}}/>

			<CategorySection data={{catName: "app", catID: "app", pathName: ""}}/>

			{/* <CategorySection data={{catName: "fashion", catID: "fashion", pathName: "fashion?category=clothing&gender=women,men"}}/>
			<SideCollection state={{type: "fashion-newest", uIDs: ["f-men"], sectionID: 6, pathName1: "fashion?category=clothing&sortBy=neuste&gender=women"}}/>
			<SideCollection state={{type: "fashion-newest", uIDs: ["f-women"], uID: "all", sectionID: 7, pathName1: "fashion?category=clothing&sortBy=neuste&gender=men"}}/> */}

			<Footer />
		</div>
	)
}

export default Home