import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Home from "./components/home/Home"
import ProductCollection from "./components/products/collection/ProductCollection"
import ProductPage from "./components/productPage/ProductPage"
import Contact from "./components/contact/Contact"
import Impressum from "./components/impressum/Impressum"
import Datenschutz from "./components/datenschutz/Datenschutz"
import Page404 from "./components/404/404"

function App() {
  return (
    <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/collection/:category" component={ProductCollection} />
          <Route path="/search/:search" component={ProductCollection} />
          <Route path="/contact" component={Contact} />
          <Route path="/product/:productUID/:productName" exact component={ProductPage} />
          <Route path="/impressum" component={Impressum} />
          <Route path="/datenschutz" component={Datenschutz} />
          <Route path="*" component={Page404} />
        </Switch>
      </Router>
  );
}

export default App;
