export const addHomeProductsJson = (json) => {
	return {
		type: "ADD_HOMEPRODUCTS_JSON",
		payload: json
	}
}

export const addHomeNewestJson = (json) => {
	return {
		type: "ADD_HOME_NEWEST_PRODUCTS_JSON",
		payload: json
	}
}

export const addHomeLargeSalesJson = (json) => {
	return {
		type: "ADD_HOME_LARGESALES",
		payload: json
	}
}

export const addScrollPosition = (position) => {
	return {
		type: "ADD_SCROLL_POSITION",
		payload: position
	}
}

export const changeSiteNumber = (number) => {
	return {
		type: "ADD_SITE_NUMBER",
		payload: number
	}
}

export const changeShouldScroll = (position) => {
	return {
		type: "SHOULD_SCROLL",
		payload: position
	}
}