import homeProductsApiReducer from "./homeProducts"
import siteNumberReducer from "./collectionSiteNumber"
import addScrollScrollReducer from "./scrollPosition"
import shouldScrollReducer from "./shouldScroll"
import {combineReducers} from "redux"
import homeLargeSalesApiReducer from "./collectionSiteNumber"
import homeNewestProductsReducer from "./homeNewestProducts"

const allReducers = combineReducers({
	homeProductsApi: homeProductsApiReducer,
	homeLargeSalesApi: homeLargeSalesApiReducer,
	siteNumber: siteNumberReducer,
	shouldScroll: shouldScrollReducer,
	scrollPosition: addScrollScrollReducer,
	homeNewestProducts: homeNewestProductsReducer
})

export default allReducers