import React from "react"
import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { changeShouldScroll, addScrollPosition, changeSiteNumber } from "../../Redux/actions"
import { motion, AnimatePresence } from "framer-motion"
import { Link } from 'react-router-dom'
import Arrow from "../../assets/SideMenu-Arrow.svg"
import "./SideMenu.css"

const SideMenu = (props) => {

	const dispatch = useDispatch()

	const [pageIndex, setPageIndex] = useState([0, 0]) // first is category, second is page cont / 0, 0 is init

	// On Click Actions
	const pagePageClick = (index) => {
		setPageIndex(index)
	}

	const backAct = () => {
		const catIndex = pageIndex[1] === 0 ? 0 : pageIndex[0]
		const pgIndex = pageIndex[1] === 0 ? 0 : pageIndex[1] - 1

		setPageIndex([catIndex, pgIndex])
	}

	//
	const onLinkClick = (catID, subCatID, gender) => {
		props.data.categoryCallback(catID, subCatID, gender)
	}

	// reset redux scroll infos
	const resetScrollData = () => {
		dispatch(changeShouldScroll(false))
		dispatch(addScrollPosition(0))
		dispatch(changeSiteNumber(1))
	}

	// UI
	// Elements
	const row = (title, withArrow, catID, subCatID, goToPageAct, gender) => {
		if (goToPageAct) {
			return (
				<button className="row" onClick={goToPageAct}>
					<p>{title}</p>
					{withArrow && <img className="arrow" src={Arrow} alt={`arrow ${title}`}/>}
				</button>
			)
		} else if (props.data.pageType !== "home") {
			return (
				<button className="row" onClick={() => onLinkClick(catID, subCatID, gender)}>
					<p>{title}</p>
					{withArrow && <img className="arrow" src={Arrow} alt={`arrow ${title}`}/>}
				</button>
			)
		} else {
			return (
				<Link className="row" to={`/collection/${catID}?category=${subCatID}${gender ? `&gender=${gender}` : ""}`} onClick={() => resetScrollData()}>
					<p>{title}</p>
					{withArrow && <img className="arrow" src={Arrow} alt={`arrow ${title}`}/>}
				</Link>
			)
		}
	}

	// Back Buton
	const backRow = () => {
		return (
			<button className="row back" onClick={backAct}>
				<img className="arrow" src={Arrow} alt="back arrow"/>
				<p>Zurück</p>
			</button>
		)
	}

	// Pages
	// Sort Pages
	const pages = () => {
		if (pageIndex[0] === 0) {
			return initPage()
		} else if (pageIndex[0] === 1) {
			return electronicPage1()
		} else if (pageIndex[0] === 2 && pageIndex[1] === 0) {
			return fashionPage1()
		} else if (pageIndex[0] === 2 && pageIndex[1] === 1) {
			return fashionPage2("women")
		} else if (pageIndex[0] === 2 && pageIndex[1] === 2) {
			return fashionPage2("men")
		}
	}

	// Initial Page
	const initPage = () => {
		return (
			<div className="page-container">
				{row("Alle", false, "all", "all")}
				{row("Elektonik", true, "", "", () => pagePageClick([1, 0]))}
				{/* {row("Fashion", true, "", "", () => pagePageClick([2, 0]))}
				{row("Tracker", false)} */}
			</div>
		)
	}

	// Electonic
	// Page 1
	const electronicPage1 = () => {
		return (
			<div className="page-container">
				{backRow()}
				{row("Smartphones", false, "electronic", "smartphone")}
				{row("TV", false, "electronic", "tv")}
				{row("Headphones", false, "electronic", "headphones")}
				{row("Computer", false, "electronic", "computer")}
				{row("Gaming", false, "electronic", "gaming")}
				{row("Monitor", false, "electronic", "monitor")}
				{row("Tablet", false, "electronic", "tablet")}
			</div>
		)
	}

	// Fashion
	// Page 1
	const fashionPage1 = () => {
		return (
			<div className="page-container">
				{backRow()}
				{row("Damen", true, "", "", () => pagePageClick([2, 1]))}
				{row("Herren", true, "", "", () => pagePageClick([2, 2]))}
			</div>
		)
	}

	// Page 2
	const fashionPage2 = (gender) => {
		return (
			<div className="page-container">
				{backRow()}
				{row("Alle", false, "fashion", "all", undefined, gender)}
				{row("Sneaker", false, "fashion", "sneaker", undefined, gender)}
				{row("Kleidung", false, "fashion", "clothing", undefined, gender)}
			</div>
		)
	}

	return (
	<div className="side-menu">
		<motion.div initial={{x: -410}} animate={{x: props.data.showMenu ? 0 : -410}} transition={{ease: [0.77, 0, 0.175, 1]}} className="side-container">
			{pages()}
		</motion.div>

		<AnimatePresence>
			{ props.data.showMenu && <motion.div initial={{opacity: 0}} animate={{opacity: 0.5}} exit={{opacity: 0}} className="overlay" onClick={() => props.data.setMenuOpenCallback(!props.data.showMenu)}></motion.div>  }
		</AnimatePresence>
	</div>
	)
}

export default SideMenu
