const addScrollScrollReducer = (state = 0, action) => {
	switch(action.type) {
		case "ADD_SCROLL_POSITION":
			return action.payload
		default: 
			return state
	}
}

export default addScrollScrollReducer
