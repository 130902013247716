import React from "react"
import { useState } from "react"
import { motion } from "framer-motion"

const duration = 0.25;

const MenuButton = (props) => {
//   const variant = isOpen ? "opened" : "closed";
//   const top = {
//     closed: {
//       rotate: 0,
//       translateY: 0
//     },
//     opened: {
//       rotate: 45,
//       translateY: 2
//     }
//   };
//   const center = {
//     closed: {
//       opacity: 1
//     },
//     opened: {
//       opacity: 0
//     }
//   };
//   const bottom = {
//     closed: {
//       rotate: 0,
//       translateY: 0
//     },
//     opened: {
//       rotate: -45,
//       translateY: -2
//     }
//   };

	// const [isOpen, setIsOpen] = useState(false)
	


  return (
	<button type="button" className="toggler" onClick={() => props.data.setMenuOpenCallback(!props.data.showMenu)} >
		<motion.div 
			className="path" 
			animate={props.data.showMenu ? "open" : "closed"}
			transition={{
				duration,
				times: [0, 0.5, 1]
			}
			}
			variants={{
				closed: {
					y: -3,
					rotate: [null, 0, 0]
				},
				open: {
					y: 2,
					rotate: [0, 0, 45]
				}
			}}
		>
		</motion.div>
		<motion.div 
			className="path" 
			animate={props.data.showMenu ? "open" : "closed"}
			transition={{
				duration,
				times: [0, 0.5, 1]
			}
			}
			variants={{
				closed: {
					y: 3,
					rotate: [null, 0, 0]
				},
				open: {
					y: -2,
					rotate: [null, 0, -45]
				}
			}}
		>
		</motion.div>
	</button>
  )
}

export default MenuButton
