import React from 'react'
import {Link} from 'react-router-dom'
// import SmartphoneBackgroundImg_L from "../../../assets/categorieBackgrounds/L/smartphone-background-L.jpg"
// import HeadphoneBackgroundImg_L from "../../../assets/categorieBackgrounds/L/headphones-background-L.jpg"
// import GamingBackgroundImg_L from "../../../assets/categorieBackgrounds/L/gaming-background-L.jpg"
// import ComputerBackgroundImg_L from "../../../assets/categorieBackgrounds/L/computer-category-background-L.jpg"
// // import SmartphoneBackgroundImg_L from "../../../assets/categorieBackgrounds/L/"
// // import SmartphoneBackgroundImg_L from "../../../assets/categorieBackgrounds/L/"
// // import SmartphoneBackgroundImg_L from "../../../assets/categorieBackgrounds/L/"
// // import SmartphoneBackgroundImg_L from "../../../assets/categorieBackgrounds/L/"
// // import SmartphoneBackgroundImg_L from "../../../assets/categorieBackgrounds/L/"
// // import SmartphoneBackgroundImg_L from "../../../assets/categorieBackgrounds/L/"
// import SmartphoneBackgroundImg_S from "../../../assets/categorieBackgrounds/S/computer-category-background-S.jpg"
import "./CategorySection.css"


function HomeHeader(props) {

	// Style
	// const containerStyle = () => {
	// 	let imagePath = ""
		// if (props.data.catName === "sneaker") {
		// 	imagePath = `url(${SneakerBackgroundImg})`
		// } else if (props.data.catName === "smartphone") {
		// 	imagePath = `url(${SmartphoneBackgroundImg})`
		// } else if (props.data.catID === "headphones") {
		// 	imagePath = `url(${HeadphoneBackgroundImg})`
		// } else if (props.data.catID === "gaming") {
		// 	imagePath = `url(${GamingBackgroundImg})`
		// } else if (props.data.catID === "fashion") {
		// 	imagePath = `url(${FashionBackgroundImg})`
		// }
 
	// 	return {
	// 		backgroundImage: imagePath
	// 	}
	// }

	const image = () => {
		const computer_S = "/categorieBackgrounds/S/computer-category-background-S.jpg"
		const computer_M = "/categorieBackgrounds/M/computer-category-background-M.jpg"
		const computer_L = "/categorieBackgrounds/L/computer-category-background-L.jpg"
		const gaming_S = "/categorieBackgrounds/S/gaming-background-S.jpg"
		const gaming_M = "/categorieBackgrounds/M/gaming-background-M.jpg"
		const gaming_L = "/categorieBackgrounds/L/gaming-background-L.jpg"
		const headphones_S = "/categorieBackgrounds/S/headphones-background-S.jpg"
		const headphones_M = "/categorieBackgrounds/M/headphones-background-M.jpg"
		const headphones_L = "/categorieBackgrounds/L/headphones-background-L.jpg"
		const kamara_S = "/categorieBackgrounds/S/kamara-category-background-S.jpg"
		const kamara_M = "/categorieBackgrounds/M/kamara-category-background-M.jpg"
		const kamara_L = "/categorieBackgrounds/L/kamara-category-background-L.jpg"
		const monitor_S = "/categorieBackgrounds/S/monitor-category-background-S.jpg"
		const monitor_M = "/categorieBackgrounds/M/monitor-category-background-M.jpg"
		const monitor_L = "/categorieBackgrounds/L/monitor-category-background-L.jpg"
		const smartphone_S = "/categorieBackgrounds/S/smartphone-background-S.jpg"
		const smartphone_M = "/categorieBackgrounds/M/smartphone-background-M.jpg"
		const smartphone_L = "/categorieBackgrounds/L/smartphone-background-L.jpg"
		const smartwatch_S = "/categorieBackgrounds/S/smartwatch-category-background-S.jpg"
		const smartwatch_M = "/categorieBackgrounds/M/smartwatch-category-background-M.jpg"
		const smartwatch_L = "/categorieBackgrounds/L/smartwatch-category-background-L.jpg"
		const tablet_S = "/categorieBackgrounds/S/tablet-category-background-S.jpg"
		const tablet_M = "/categorieBackgrounds/M/tablet-category-background-M.jpg"
		const tablet_L = "/categorieBackgrounds/L/tablet-category-background-L.jpg"
		const tv_S = "/categorieBackgrounds/S/tv-category-background-S.jpg"
		const tv_M = "/categorieBackgrounds/M/tv-category-background-M.jpg"
		const tv_L = "/categorieBackgrounds/L/tv-category-background-L.jpg"
		const app = "/categorieBackgrounds/app-banner.jpg"

		if (props.data.catID === "computer") {
			return <img className="bg-image" src={computer_S} srcSet={`${computer_S} 1000w, ${computer_M} 2000w, ${computer_L} 2600w`} alt="computer-background"/>

		} else if (props.data.catID === "gaming") {
			return <img className="bg-image" src={gaming_S} srcSet={`${gaming_S} 1000w, ${gaming_M} 2000w, ${gaming_L} 2600w`} alt="gaming-background"/>

		} else if (props.data.catID === "headphones") {
			return <img className="bg-image" src={headphones_S} srcSet={`${headphones_S} 1000w, ${headphones_M} 2000w, ${headphones_L} 2600w`} alt="headphones-background"/>

		} else if (props.data.catID === "camara") {
			return <img className="bg-image" src={kamara_S} srcSet={`${kamara_S} 1000w, ${kamara_M} 2000w, ${kamara_L} 2600w`} alt="camara-background"/>

		} else if (props.data.catID === "monitor") {
			return <img className="bg-image" src={monitor_S} srcSet={`${monitor_S} 1000w, ${monitor_M} 2000w, ${monitor_L} 2600w`} alt="monitor-background"/>

		} else if (props.data.catID === "smartphone") {
			return <img className="bg-image" src={smartphone_S} srcSet={`${smartphone_S} 1000w, ${smartphone_M} 2000w, ${smartphone_L} 2600w`} alt="smartphone-background"/>

		} else if (props.data.catID === "smartwatch") {
			return <img className="bg-image" src={smartwatch_S} srcSet={`${smartwatch_S} 1000w, ${smartwatch_M} 2000w, ${smartwatch_L} 2600w`} alt="smartwatch-background"/>

		} else if (props.data.catID === "tablet") {
			return <img className="bg-image" src={tablet_S} srcSet={`${tablet_S} 1000w, ${tablet_M} 2000w, ${tablet_L} 2600w`} alt="tablet-background"/>

		} else if (props.data.catID === "tv") {
			return <img className="bg-image" src={tv_S} srcSet={`${tv_S} 1000w, ${tv_M} 2000w, ${tv_L} 2600w`} alt="tv-background"/>

		} else if (props.data.catID === "app") {
			return <img className="bg-image" src={app} alt="app-banner"/>
		}
	}

	return(
		// <Link className="CategorySection" to={`collection/${props.data.pathName}`} style={containerStyle()}>
		<div>
			{ props.data.catID === "app" ?
				<a className="CategorySection app-banner" href="https://apps.apple.com/de/app/deal-agent/id1531618416" target="_blank" rel="noreferrer">
					{image()}
				</a>
			:
				<Link className="CategorySection" to={`collection/${props.data.pathName}`}>
					{image()}
					<h3>Die besten {props.data.catName.toUpperCase()} Deals</h3>
				</Link>
			}
		</div>
	)
}

export default HomeHeader